::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.kyrie-container {
  padding: 0;
}

.ipsum-container {
  overflow-y: scroll;
  height: 100vh;
  padding: 24px 36px;
}

.App {
  font-family: Futura, Trebuchet MS, Arial, sans-serif;
  max-width: 100%;
}

.App,
.rc-slider-handle:hover,
.rc-slider-handle:active,
.rc-slider-mark-text,
.rc-slider-mark-text-active,
.rc-slider-dot:hover,
.rc-slider-dot:active,
.rc-slider-dot-active:hover,
.rc-slider-dot-active:active,
.btn:hover {
  cursor: url(../images/cursor.png) 13 4, auto;
}

.rc-slider-track {
  background-color: #002a60;
}

.rc-slider-dot-active {
  border-color: #002a60;
}

.rc-slider-handle {
  background-color: #002a60;
  border: #002a60;
}

.rc-slider-handle:active {
  border-color: #002a60;
  box-shadow: none;
}

.rc-slider-handle:focus {
  border-color: #002a60;
  box-shadow: none;
  outline: none;
}

.ipsum-paragraph {
  animation: fadein 500ms;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.background-image {
  transition: all 1s ease;
  height: 100vh;
}

.background-image:hover {
  height: 105vh;
}
